
<template>
  <div id="page-user-rdit">
    <div v-if="user_not_found">
      <b-alert show variant="danger">
        <h5 class="alert-heading"><i class="mdi mdi-block-helper mr-2"></i> Record Not Found!</h5>
        <hr class="my-1">
        <p class="mb-0">
          Sorry something went wrong. May be server issues. Refresh!
        </p>
      </b-alert>
    </div>
    <div v-if="!isLoading">
      <div class="row">
        <div class="col-lg-4 col-xl-4">
            <div class="card">
                <div class="card-body text-center">
                    <img :src="$store.state.domain+'/'+activeUser.image" class="rounded-circle avatar-xl img-thumbnail" alt="profile-image" />
                    <h4 class="mt-3 mb-0">{{activeUser.first_name}} {{activeUser.last_name}}</h4>
                    <p class="text-muted">Type: {{activeUser.role}}</p>
                    <button type="button" class="btn mr-1 btn-success btn-xs waves-effect mb-2 waves-light">
                      {{activeUser.status}}
                    </button>
                    <span>
                    <button type="button" class="btn btn-xs waves-effect mb-2 waves-light" :class="activeUser.is_verified ? 'btn-info' : 'btn-danger'">
                      {{activeUser.is_verified ? 'Verified' : 'Not Verified'}}
                    </button>
                    </span>
                </div>
            </div>
        </div>
        <!-- end col-->
        <div class="col-lg-8 col-xl-8">
            <div class="card">
                <div class="card-body">
                    <b-tabs content-class="mt-1" pills class="navtab-bg">
                        <b-tab :active="activeTab === 'profile'">
                            <template v-slot:title>
                                <i class="mdi mdi-account-check mr-1"></i> Profile
                            </template>
                            <user-edit-tab-account />
                        </b-tab>
                        <b-tab :active="activeTab === 'settings'">
                        <template v-slot:title>
                            <i class="mdi mdi-account-cog mr-1"></i> Settings
                        </template>
                            <user-edit-tab-information />
                        </b-tab>
                        <b-tab :active="activeTab === 'password'">
                        <template v-slot:title>
                            <i class="mdi mdi-lock-check mr-1"></i> Password
                        </template>
                            <user-edit-tab-password />
                        </b-tab>
                    </b-tabs>
                </div>
                <!-- end card-box-->
            </div>
        </div>
        <!-- end col -->
    </div>
    </div>
  </div>
</template>

<script>
import UserEditTabAccount     from "./UserEditTabAccount.vue"
import UserEditTabInformation from "./UserEditTabInformation.vue"
import UserEditTabPassword from "./UserEditTabPassword.vue"

export default {
  components: {
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabPassword,
  },
  data() {
    return {
      isLoading: true,
      user_not_found: false,
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    activeTab(){
      return this.$route.query.tab ? this.$route.query.tab : 'profile';
    },
  },
  created() {
    this.$store.dispatch("fetchActiveUser")
        .then((response) => {
        if(response.data.success){
            this.isLoading = false
        }else{
            this.alertError(response.data.error)
            this.user_not_found = true
        }
    }).catch(err => { console.error(err); this.user_not_found = true });

  }
}

</script>
